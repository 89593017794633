import axios from 'axios';

export default function loadMoreMediadata() {
    let page = 1;
    const mediadataTabSlider = document.querySelector('.js-mediadata-wrap');
    if (!mediadataTabSlider) {
        return;
    }
    const loadEvents = mediadataTabSlider.querySelector('.js-load-mediadata');
    const mediadataList = mediadataTabSlider.querySelector('.js-mediadata-list');

    function runLoaderMediadata() {
        page += 1;
        if (page >= loadEvents.dataset.maxpage) {
            loadEvents.classList.add('hide');
        }

        const ajaxurl = '/wp-admin/admin-ajax.php';
        const data = new URLSearchParams({
            action: 'get_ajax_posts_mediadata',
            get_page: page,
            get_category: loadEvents.dataset.category,
            get_tax: loadEvents.dataset.tax,
        });

        axios.post(ajaxurl, data)
            .then((response) => {
                mediadataList.insertAdjacentHTML('beforeend', response.data);
            });
    }

    loadEvents.addEventListener('click', runLoaderMediadata, false);
}
window.addEventListener('load', loadMoreMediadata);
